import * as types from './types.js'

// TRACK
// TRACKING
export const tracker =
  (type, actionData = {}) =>
  (dispatch, getState) => {
    const { track } = getState()
    const payload = { ...track, ...actionData }

    return dispatch({
      type,
      payload,
      meta: {
        analytics: {
          type,
          payload
        }
      }
    })
  }

export const trackSubmit = () => tracker(types.SUBMIT)

export const trackSubmitSuccess = (mfaStatus, nmUniqueId) => tracker(types.SUBMIT_SUCCESS, { mfaStatus, nmUniqueId })

export const trackSubmitError = () => tracker(types.SUBMIT_ERROR)

export const trackShowPassword = (textState) => tracker(types.SHOW_PASSWORD, { textState })

export const trackShowResetPasswordModal = () => tracker(types.SHOW_RESET_PASSWORD_MODAL)

export const trackClickResetPasswordLink = () => tracker(types.CLICK_RESET_PASSWORD_LINK)

export const trackClickResetPasswordCancel = () => tracker(types.CLICK_RESET_PASSWORD_CANCEL)

export const trackLockedOut = () => tracker(types.LOCKED_OUT)

export function testUsername(value) {
  if (value && value.length < 3) {
    return ''
  }

  return ''
}

export const acknowledgeBadBrowser = () => (dispatch) => {
  tracker(types.ACKNOWLEDGE_BAD_BROWSER)
  dispatch({ type: types.ACKNOWLEDGE_BAD_BROWSER })
}

export const setFormResetModal = () => ({
  type: types.SET_FORM_RESET_MODAL
})

export const setNmUniqueId = (payload) => ({
  type: types.SET_NMUNIQUEID,
  payload
})

export const clearFormResetModal = () => ({
  type: types.CLEAR_FORM_RESET_MODAL
})

export const updateSubmitDisabled = (payload) => ({
  type: types.UPDATE_FORM_SUBMIT_DISABLED,
  payload
})

export const updateSubmitText = (payload) => ({
  type: types.UPDATE_FORM_SUBMIT_TEXT,
  payload
})

export const updateSubmitWait = (payload) => ({
  type: types.UPDATE_FORM_SUBMIT_WAIT,
  payload
})

export const validateUsername = ({ target }) => {
  const { value } = target
  const payload = testUsername(value)

  return {
    type: types.UPDATE_FORM_USERNAME_ERROR,
    payload
  }
}

export const validatePassword = () => {
  const payload = ''

  return {
    type: types.UPDATE_FORM_PASSWORD_ERROR,
    payload
  }
}

export const updateFormError = (payload) => ({
  type: types.UPDATE_FORM_ERROR,
  payload
})

export const updateFormStatus = () => (dispatch, getState) => {
  const { form } = getState()
  const { username, usernameError, password, passwordError } = form
  if (!username || usernameError || !password || passwordError) {
    dispatch(updateSubmitText('Log In'))

    return dispatch(updateSubmitDisabled(true))
  }

  if (testUsername(username)) {
    return dispatch(updateSubmitDisabled(true))
  }

  dispatch(updateSubmitText('Log In'))

  return dispatch(updateSubmitDisabled(false))
}

export const updateSubmitCount = () => (dispatch, getState) => {
  const { track } = getState()
  const { attempt } = track
  if (attempt % 5 === 0) {
    dispatch(trackShowResetPasswordModal())
    dispatch(setFormResetModal())
  } else {
    dispatch(clearFormResetModal())
  }

  return dispatch({
    type: types.UPDATE_FORM_SUBMIT_ATTEMPS
  })
}

export const updateUsername = ({ target }) => {
  const { value } = target

  return (dispatch) => {
    dispatch({
      type: types.UPDATE_FORM_USERNAME,
      payload: value
    })
    dispatch(updateFormStatus())
  }
}

export const updatePassword = ({ target }) => {
  const { value } = target

  return (dispatch) => {
    dispatch({
      type: types.UPDATE_FORM_PASSWORD,
      payload: value
    })
    dispatch(updateFormStatus())
  }
}

export const postFormSuccess = (payload) => ({
  type: types.FORM_COMPLETE_POST_SUCCESS,
  payload
})

export const userlockedOut = () => ({
  type: types.UPDATE_FORM_LOCKED_OUT
})

export const userDeactivated = () => ({
  type: types.UPDATE_FORM_DEACTIVATED
})

export const formPasswordError = () => ({
  type: types.UPDATE_FORM_PASSWORD_ERROR,
  payload: "Sorry, we can't find that username or password."
})

export const showBookmarkError = () => ({
  type: types.UPDATE_FORM_BOOKMARK_ERROR
})

export const postLogin = (username, password, actionToken) => (dispatch, getState, fetchMethod) =>
  fetchMethod('/login/api', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'x-csrf-token': window._csrf
    },
    body: JSON.stringify({
      username,
      password,
      actionToken
    })
  })
    .then(({ redirectUrl, status, nmUniqueId }) => {
      try {
        if (window.heap) {
          window.heap.identify(nmUniqueId)
        }
      } catch {
        //
      }

      dispatch(trackSubmitSuccess(status, nmUniqueId))
      dispatch(setNmUniqueId(nmUniqueId))

      if (!redirectUrl) {
        throw new Error('no redirect url')
      }

      if (status === 'DEPROVISIONED') {
        dispatch(updateSubmitWait(false))
        dispatch(updateSubmitText('Log In'))
        dispatch(updateSubmitDisabled(true))

        return dispatch(userDeactivated())
      }

      window.location.href = redirectUrl

      return {}
    })
    .catch(({ status }) => {
      dispatch(updateSubmitCount())
      dispatch(trackSubmitError())
      dispatch(updateSubmitWait(false))
      dispatch(updateSubmitText('Log In'))
      dispatch(updateSubmitDisabled(true))
      dispatch(updateFormError(true))
      if (status === 401) {
        return dispatch(formPasswordError())
      }

      if (status === 404) {
        dispatch(updateUsername({ target: { value: '' } }))

        return dispatch({
          type: types.UPDATE_FORM_USERNAME_ERROR,
          payload: 'Sorry, we could not find that username.'
        })
      }

      if (status === 423) {
        dispatch(trackLockedOut())
        dispatch(updatePassword({ target: { value: '' } }))

        return dispatch(userlockedOut())
      }

      return dispatch({
        type: types.UPDATE_FORM_PASSWORD_ERROR,
        payload: 'An unknown error occured.'
      })
    })

const getDRSActionToken = async () =>
  new Promise((resolve) => {
    try {
      window.tsPlatform.drs.triggerActionEvent('login').then((actionResponse) => {
        resolve(actionResponse.actionToken)
      })
    } catch {
      resolve('')
    }
  })

export const postForm = (event) => {
  if (event) {
    event.preventDefault()
  }

  return (dispatch, getState) =>
    getDRSActionToken().then((actionToken) => {
      const { form, submit } = getState()
      const username = form.username ? form.username.trim() : ''
      const password = form.password || ''

      if (!submit.disabled) {
        dispatch(updateSubmitDisabled(true))
        dispatch(updateSubmitText('Verifying'))
        dispatch(updateSubmitWait(true))
        dispatch(updateFormError(false))
        dispatch(trackSubmit())
        dispatch(postLogin(username, password, actionToken))
      }

      return null
    })
}

export const setGreetingMessage = () => {
  const time = new Date().getHours()
  if (time < 12) {
    return 'Good morning!'
  }

  if (time >= 12 && time < 18) {
    return 'Good afternoon!'
  }

  return 'Good evening!'
}

export const getGreetingMessage = () => {
  const payload = setGreetingMessage()

  return {
    type: types.UPDATE_GREETING_MESSAGE,
    payload
  }
}

export const setResponseErrorModal = () => ({
  type: types.SET_RESPONSE_ERROR_MODAL
})

export const generateTicketId = () => (dispatch, getState, fetchMethod) => {
  const { nmUniqueId } = getState().meta

  return fetchMethod('/login/create-csm', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'csrf-token': window._csrf
    },
    body: JSON.stringify({
      meta: {
        flow: 'ACCT_RECOVERY_WELCOME_BACK'
      },
      nmuid: nmUniqueId
    })
  })
}
