export const ACKNOWLEDGE_BAD_BROWSER = 'login/ACKNOWLEDGE_BAD_BROWSER'

// USERNAME
export const UPDATE_FORM_USERNAME = 'login/UPDATE_FORM_USERNAME'
export const UPDATE_FORM_USERNAME_ERROR = 'login/UPDATE_FORM_USERNAME_ERROR'

// PASSWORD
export const UPDATE_FORM_PASSWORD_ERROR = 'login/UPDATE_FORM_PASSWORD_ERROR'
export const UPDATE_FORM_PASSWORD = 'login/UPDATE_FORM_PASSWORD'

// SUBMIT BUTTON
export const UPDATE_FORM_SUBMIT_DISABLED = 'login/UPDATE_FORM_SUBMIT_DISABLED'
export const UPDATE_FORM_SUBMIT_TEXT = 'login/UPDATE_FORM_SUBMIT_TEXT'
export const UPDATE_FORM_SUBMIT_WAIT = 'login/UPDATE_FORM_SUBMIT_WAIT'
export const UPDATE_FORM_LOCKED_OUT = 'login/UPDATE_FORM_LOCKED_OUT'
export const SET_FORM_RESET_MODAL = 'login/SET_FORM_RESET_MODAL'
export const CLEAR_FORM_RESET_MODAL = 'login/CLEAR_FORM_RESET_MODAL'
export const UPDATE_FORM_DEACTIVATED = 'login/UPDATE_FORM_DEACTIVATED'
export const SET_RESPONSE_ERROR_MODAL = 'login/UPDATE_RESPONSE_ERROR_MODAL'
export const SET_NMUNIQUEID = 'login/SET_NMUNIQUEID'

// FORM
export const SUBMIT_FORM = 'login/SUBMIT_FORM'
export const FORM_COMPLETE_POST_SUCCESS = 'login/FORM_COMPLETE_POST_SUCCESS'
export const UPDATE_FORM_ERROR = 'login/UPDATE_FORM_ERROR'
export const UPDATE_GREETING_MESSAGE = 'login/UPDATE_GREETING_MESSAGE'

// TRACK
export const SUBMIT = 'login/SUBMIT'
export const UPDATE_FORM_SUBMIT_ATTEMPS = 'login/UPDATE_FORM_SUBMIT_ATTEMPS'
export const SUBMIT_SUCCESS = 'login/SUBMIT_SUCCESS'
export const SUBMIT_ERROR = 'login/SUBMIT_ERROR'
export const SHOW_PASSWORD = 'login/SHOW_PASSWORD'
export const CLICK_RESET_PASSWORD_LINK = 'login/CLICK_RESET_PASSWORD_LINK'
export const CLICK_RESET_PASSWORD_CANCEL = 'login/CLICK_RESET_PASSWORD_CANCEL'
export const LOCKED_OUT = 'login/LOCKED_OUT'
export const SHOW_RESET_PASSWORD_MODAL = 'login/SHOW_RESET_PASSWORD_MODAL'

// BOOKMARK ERROR
export const UPDATE_FORM_BOOKMARK_ERROR = 'login/UPDATE_FORM_BOOKMARK_ERROR'
