import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getGreetingMessage } from '../store/actions'
import { MainText, SubText } from './component-styles'

class Greeting extends PureComponent {
  componentDidMount() {
    this.props.dispatchGetGreetingMessage()
  }

  render() {
    const { greetingMessageSite } = this.props

    return (
      <div className='greeting'>
        <MainText className='primary-color'>Welcome.</MainText>
        <SubText className='silver-dollar'>{greetingMessageSite}</SubText>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  greetingMessageTime: state.form.greetingMessageTime,
  greetingMessageSite: state.form.greetingMessageSite
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dispatchGetGreetingMessage: getGreetingMessage
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Greeting)
