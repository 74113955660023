import React from 'react'
import { hydrate } from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import ensurePolyfills from './utils/ensure-polyfills'
import App from './components/app'
import createStore from './store/create-store'
import makeFetch from './utils/make-fetch'

const renderEntry = (Component) =>
  ensurePolyfills(() => {
    const app = document.getElementById('app')
    const store = createStore(window.__STATE__, makeFetch)

    try {
      if (window.newrelic) {
        window.newrelic.setCustomAttribute('nmUniqueId', window.__STATE__.meta.nmUniqueId)
        window.newrelic.setCustomAttribute('isBot', window.isBot)
      }
    } catch (e) {
      //
    }

    try {
      if (window.heap) {
        const analyticsDataLayer = window.analyticsDataLayer[0] || {}
        const { cmpid, utm_source, utm_medium, utm_term, utm_campaign, utm_content } = analyticsDataLayer
        window.heap.addUserProperties({
          registration_invite_code: window.__STATE__.invite.inviteCode || 'none',
          cmpid,
          utm_source,
          utm_medium,
          utm_term,
          utm_campaign,
          utm_content
        })
      }
    } catch (e) {
      //
    }

    try {
      if (window._satellite) {
        if (window.isBot) {
          window._satellite.setVar('experience-type', 'bot')
        }
      }
    } catch (err) {
      //
    }

    hydrate(
      <AppContainer>
        <Component store={store} />
      </AppContainer>,
      app
    )
  })

renderEntry(App)

if (module.hot) {
  module.hot.accept('./components/app', () => {
    const RootContainer = require('./components/app').default
    renderEntry(RootContainer)
  })
}
