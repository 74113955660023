import * as types from '../types.js'

export const initialState = {
  title: 'Login | Northwestern Mutual',
  nmUniqueId: 'no_user',
  supportHours: {},
  clearSession: false,
  welcomeBackModalFeatureFlag: false,
  logoutUrls: {
    urls: {},
    nmisLogoutUrl: {}
  },
  authRecoveryUrls: {
    passwordRecoveryUrl: '',
    usernameRecoveryUrl: ''
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_NMUNIQUEID: {
      return { ...state, nmUniqueId: payload }
    }

    default: {
      return state
    }
  }
}
