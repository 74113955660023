import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Modal from '@cx/couturier/labs/Modal'
import { generateTicketId, userDeactivated } from '../../store/actions.js'

const WelcomeBackModal = ({ deactivatedModal, dispatchUserDeactivated, featureFlag, dispatchGenerateTicketId }) => {
  const modalText = featureFlag
    ? 'We need to verify your identity and add an extra layer of security to your account to ensure your information remains secure. This process should only take a few minutes.'
    : 'It’s been a while since you logged in, so we need to confirm a few details and reset your password — it should only take 2 minutes.'

  const handleRedirect = async () => {
    if (featureFlag) {
      const { ticketID } = await dispatchGenerateTicketId()

      window.location.href = `/id-proof?ticketId=${ticketID}`
    } else {
      window.location.href = '/welcome-back'
    }
  }

  return (
    <Modal
      confirmText={featureFlag ? 'Get Started' : "Let's Go!"}
      contentLabel='Welcome Back modal'
      description='Welcome Back'
      isOpen={deactivatedModal}
      name='deactivated-user'
      onClose={dispatchUserDeactivated}
      onConfirm={handleRedirect}
      onRequestClose={() => {}}
      size='medium'
      titleText='Welcome Back!'
    >
      <p>{modalText}</p>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  deactivatedModal: state.submit.deactivatedModal,
  featureFlag: state.meta.welcomeBackModalFeatureFlag
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dispatchUserDeactivated: userDeactivated,
      dispatchGenerateTicketId: generateTicketId
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeBackModal)
